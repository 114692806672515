<script lang="ts" setup>
const { t } = useT();
const { slicedGames, menu, showLoadMoreButton, favorites, load, handleLoadMoreClick } = useGamesCatalog();
const { gameName, isExpanded } = useGameSearch();

defineProps<{
	showCatalog: boolean;
}>();

const gamesConteiner = ref<HTMLElement | null>(null);
const activeTab = ref("lobby");

const scrollCondition = computed(
	() => slicedGames.value?.length && (favorites.value?.length || activeTab.value !== "favorites")
);

const scrollIntoCatalog = () => {
	if (!isClient) {
		return;
	}

	if (window?.scrollY > (gamesConteiner.value?.offsetTop ?? 0)) {
		window.scrollTo({
			top: gamesConteiner.value?.offsetTop,
			behavior: "smooth"
		});
	}
};

const handleClickTab = (link: string) => {
	const item = link.split("/")[2];
	activeTab.value = item;
	scrollIntoCatalog();

	if (item === "lobby") {
		return;
	}

	load(item);
};

onMounted(() => {
	const activeUrl = useState("active-url-games");
	activeUrl.value = "/";
	gameName.value = "";
	isExpanded.value = false;
});
</script>

<template>
	<div ref="gamesConteiner" class="games-conteiner" />
	<div :class="['catalog', { fixed: !showCatalog }]">
		<OGamesNavigation :menu="menu" type="button" show-lobby class="wrapper-nav" @click-tab="handleClickTab" />
		<template v-if="showCatalog">
			<LazyOLoadMoreOnScroll
				v-if="scrollCondition"
				:canLoadMore="showLoadMoreButton"
				@load-more="handleLoadMoreClick()"
			>
				<OGames :games="slicedGames" />
			</LazyOLoadMoreOnScroll>

			<template v-if="activeTab === 'favorites' && !favorites?.length">
				<MGameFavoriteEmpty />
				<template v-if="slicedGames?.length">
					<AText type="h8 h5-md mb-5" as="h3">{{ t("Recent") }}</AText>
					<OGames :games="slicedGames" />
				</template>
			</template>
		</template>
	</div>
</template>

<style scoped lang="scss">
.wrapper-nav {
	width: 100%;

	&:deep(+ *:not(.border)) {
		padding-top: 24px;

		@include media-breakpoint-down(lg) {
			padding-top: 8px;
		}
	}
}

.wrapper .games-conteiner {
	display: block;
	margin-bottom: -40px;
	@include media-breakpoint-down(lg) {
		margin-bottom: -32px;
	}
}

.fixed {
	position: sticky;
	top: var(--top-height);
	z-index: 10;
}

.catalog {
	width: 100%;
	transform: translateY(-2px);

	&:deep(.fixed + .section-item) {
		padding-top: 20px;
	}

	&:deep(.grid-cards) {
		margin-bottom: 32px;
	}
}

.favorite-empty {
	padding-bottom: 32px;
}
</style>
